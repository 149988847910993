@use './font'as f;
@use './colours'as c;

html {
	box-sizing: border-box;
	font-size: 16px;
	font-family: f.$font-main;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
	font-weight: normal;
	color: c.$secondary-text-color-dark;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
figure {
	margin: 0;
	padding: 0;
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}

body {
	margin: 0;
	font-family: f.$font-main;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
