@use '../functions'as f;

//https://css-tricks.com/snippets/sass/px-to-em-functions/
$browser-context: 16; // Default

// http://thesassway.com/intermediate/responsive-web-design-in-sass-using-media-queries-in-sass-32
@mixin mq-portrait() {
	@media (orientation:portrait) {
		@content;
	}
}

@mixin mq-landscape() {
	@media (orientation:landscape) {
		@content;
	}
}

@mixin mq-touch() {
	@media(pointer:coarse) {
		@content;
	}
}

@mixin mq-mouse() {
	@media(pointer:fine) {
		@content;
	}
}

@mixin has-hover() {
	@media(hover:hover) {
		@content;
	}
}

@mixin no-hover() {
	@media(hover:none) {
		@content;
	}
}

$break-width-xxxs: 325; // 5px bigger than Iphone 5/SE
$break-width-xxs: 380;
$break-width-xs: 480;
$break-width-s: 640;
$break-width-m: 768;
$break-width-l: 1024;
$break-width-xl: 1280;
$break-width-xxl: 1536;
$break-width-xxxl: 1920;

$break-height-xs: 340;
$break-height-s: 480; // 30 em
$break-height-m: 640; // 40 em
$break-height-l: 768; // 48 em
$break-height-xl: 1024; // 64 em
$break-height-xxl: 1200; // 75 em

@mixin respond-to-wdith($media) {
	@if $media==xs {
		@include mq-width-to($break-width-xs);
	}

	@else if $media==s {
		@include mq-width($break-width-xs, $break-width-s);
	}

	@else if $media==m {
		@include mq-width($break-width-s, $break-width-m);
	}

	@else if $media==l {
		@include mq-width($break-width-m, $break-width-l);
	}

	@else if $media==xl {
		@include mq-width-from($break-width-xl);
	}
}

@mixin respond-to-height($media) {
	@if $media==s {
		@include mq-width-to($break-height-s);
	}

	@else if $media==m {
		@include mq-width-from($break-height-m);
	}
}

// WIDTH
@mixin mq-width-from($from) {
	@media only screen and (min-width:f.em($from)) {
		@content;
	}
}

@mixin mq-width-to($to) {
	@media only screen and (max-width:f.em($to)) {
		@content;
	}
}

@mixin mq-width($from, $to) {
	@media only screen and (min-width:f.em($from)) and (max-width:f.em($to)) {
		@content;
	}
}

// HEIGHT
@mixin mq-height-from($from) {
	@media only screen and (min-height:f.em($from)) {
		@content;
	}
}

@mixin mq-height-to($to) {
	@media only screen and (max-height:f.em($to)) {
		@content;
	}
}

@mixin mq-height($from, $to) {
	@media only screen and (min-height:f.em($from)) and (max-height:f.em($to)) {
		@content;
	}
}

// ASPECT RATIO

@mixin mq-min-aspect-ratio($min-x, $min-y) {
	@media (min-aspect-ratio:#{$min-x} / #{$min-y}) {
		@content;
	}
}
