@use './mq'as mq;
@use './flexbox.scss'as fb;

$value: null;
$percentage: null;

@mixin column($value: 0) {
	@if $value !=0 {
		$percentage: #{($value / 12) * 100%};
		max-width: $percentage;
		@include fb.flex(0, 0, $percentage);
	}

	@else {
		@include fb.flex(1, 1, 100%);
		max-width: 100%;
	}
}

@mixin auto {
	max-width: 100%;
	width: auto;
	@include fb.flex(1, 1, auto);
}

@mixin divider-vertical {
	margin: 0 rem(20);
	flex: 0 0 1px;
	height: auto;
}

.row {
	@include fb.flexbox();
	@include fb.flex-wrap(wrap);
}

.col {
	@include column();
}

.col-auto {
	@include auto;
}

// Creates column classes without media query
@for $i from 1 through 12 {
	.col-#{$i} {
		@include column($i);
	}
}

@include mq.mq-width-from(mq.$break-width-s) {
	.col-s {
		@include column();
	}

	.col-s-auto {
		@include auto;
	}

	.divider-s {
		@include divider-vertical;
	}

	@for $i from 1 through 12 {
		.col-s-#{$i} {
			@include column($i);
		}
	}
}

@include mq.mq-width-from(mq.$break-width-m) {
	.col-m {
		@include column();
	}

	.col-m-auto {
		@include auto;
	}

	.divider-m {
		@include divider-vertical;
	}

	@for $i from 1 through 12 {
		.col-m-#{$i} {
			@include column($i);
		}
	}
}

@include mq.mq-width-from(mq.$break-width-l) {
	.col-l {
		@include column();
	}

	.col-l-auto {
		@include auto;
	}

	.divider-l {
		@include divider-vertical;
	}

	@for $i from 1 through 12 {
		.col-l-#{$i} {
			@include column($i);
		}
	}
}

@include mq.mq-width-from(mq.$break-width-xl) {
	.col-xl {
		@include column();
	}

	.col-xl-auto {
		@include auto;
	}

	.divider-xl {
		@include divider-vertical;
	}

	@for $i from 1 through 12 {
		.col-xl-#{$i} {
			@include column($i);
		}
	}
}

@include mq.mq-width-from(mq.$break-width-xxl) {
	.col-xxl {
		@include column();
	}

	.col-xxl-auto {
		@include auto;
	}

	.divider-xxl {
		@include divider-vertical;
	}

	@for $i from 1 through 12 {
		.col-xxl-#{$i} {
			@include column($i);
		}
	}
}
