@use "sass:map";
@use './values-map.scss'as vm;

@mixin padding($value) {
	padding: map.get(vm.$values, $value);
}

@mixin padding-x($value) {
	$v: map.get(vm.$values, $value);
	padding-left: $v;
	padding-right: $v;
}

@mixin padding-y($value) {
	$v: map.get(vm.$values, $value);
	padding-top: $v;
	padding-bottom: $v;
}

@mixin padding-r($value) {
	padding-right: map.get(vm.$values, $value);
}

@mixin padding-l($value) {
	padding-left: map.get(vm.$values, $value);
}

@mixin padding-t($value) {
	padding-top: map.get(vm.$values, $value);
}

@mixin padding-b($value) {
	padding-bottom: map.get(vm.$values, $value);
}

@mixin margin($value) {
	margin: map.get(vm.$values, $value);
}

@mixin margin-x($value) {
	$v: map.get(vm.$values, $value);
	margin-left: $v;
	margin-right: $v;
}

@mixin margin-y($value) {
	$v: map.get(vm.$values, $value);
	margin-top: $v;
	margin-bottom: $v;
}

@mixin margin-r($value) {
	margin-right: map.get(vm.$values, $value);
}

@mixin margin-l($value) {
	margin-left: map.get(vm.$values, $value);
}

@mixin margin-t($value) {
	margin-top: map.get(vm.$values, $value);
}

@mixin margin-b($value) {
	margin-bottom: map.get(vm.$values, $value);
}
