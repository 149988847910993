//
// Vertical Rhythm
//
// This is the minimal amount of code needed to create vertical rhythm in our
// CSS. If you are looking for a robust solution, look at the excellent Typey
// library. @see https://github.com/jptaranto/typey

@function normalize-rhythm($value, $relative-to: $base-font-size, $unit: $base-unit) {
	@if unit($value) !=px {
		@error "The normalize vertical-rhythm module only supports px inputs. The typey library is better.";
	}

	@if $unit==rem {
		@return ($value / $base-font-size) * 1rem;
	}

	@else if $unit==em {
		@return ($value / $relative-to) * 1em;
	}

	@else {
		// $unit == px
		@return $value;
	}
}

@mixin normalize-font-size($value, $relative-to: $base-font-size) {
	@if unit($value) !='px' {
		@error "normalize-font-size() only supports px inputs. The typey library is better.";
	}

	font-size: normalize-rhythm($value, $relative-to);
}

@mixin normalize-rhythm($property, $values, $relative-to: $base-font-size) {
	$value-list: $values;
	$sep: space;

	@if type-of($values)=='list' {
		$sep: list-separator($values);
	}

	@else {
		$value-list: append((), $values);
	}

	$normalized-values: ();

	@each $value in $value-list {
		@if unitless($value) and $value !=0 {
			$value: $value * normalize-rhythm($base-line-height, $relative-to);
		}

		$normalized-values: append($normalized-values, $value, $sep);
	}

	#{$property}: $normalized-values;
}

@mixin normalize-margin($values, $relative-to: $base-font-size) {
	@include normalize-rhythm(margin, $values, $relative-to);
}

@mixin normalize-line-height($font-size, $min-line-padding: 2px) {
	$lines: ceil($font-size / $base-line-height);

	// If lines are cramped include some extra leading.
	@if ($lines * $base-line-height - $font-size) < ($min-line-padding * 2) {
		$lines: $lines + 1;
	}

	@include normalize-rhythm(line-height, $lines, $font-size);
}
