@use './functions'as f;

$border-radius-default: 5px;

/// FONT SIZE VARIABLES ///
$base-font-size: 16;

$font-size-xxs: $base-font-size * .625;
$font-size-xs: $base-font-size * .6875;
$font-size-s: $base-font-size * .875;
$font-size-default: $base-font-size * 1;
$font-size-m: $base-font-size * 1.25;
$font-size-l: $base-font-size * 1.5;
$font-size-xl: $base-font-size * 2;

$font-rem-xxs: f.rem($font-size-xxs);
$font-rem-xs: f.rem($font-size-xs);
$font-rem-s: f.rem($font-size-s);
$font-rem-default: f.rem($font-size-default);
$font-rem-m: f.rem($font-size-m);
$font-rem-l: f.rem($font-size-l);
$font-rem-xl: f.rem($font-size-xl);
