@use '../../../styles/layout/mq'as mq;
@use '../../../styles/functions'as f;
@use '../../../styles/layout/padding-margin.scss'as pm;

.root {
	@include pm.margin-x(auto);

	max-width: 95%;

	@include mq.mq-width-from(mq.$break-width-xxxs) {
		max-width: 85%;
	}

	// @include mq.mq-width-from(mq.$break-width-xxs) {
	// 	max-width: f.em(mq.$break-width-xxs);
	// }

	@include mq.mq-width-from(mq.$break-width-s) {
		max-width: f.em(mq.$break-width-xs + 100);
	}

	@include mq.mq-width-from(mq.$break-width-m) {
		max-width: f.em(mq.$break-width-s + 100);
	}

	@include mq.mq-width-from(mq.$break-width-l) {
		max-width: f.em(mq.$break-width-m + 100);
	}

	@include mq.mq-width-from(mq.$break-width-xl) {
		max-width: f.em(mq.$break-width-l + 100);
	}

	@include mq.mq-width-from(mq.$break-width-xxl) {
		max-width: f.em(mq.$break-width-xl + 100);
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		max-width: 80vw;
	}
}
