@use "sass:map";
@use './values-map.scss'as vm;

@mixin height($value) {
	height: map.get(vm.$values, $value);
}

@mixin min-height($value) {
	min-height: map.get(vm.$values, $value);
}

@mixin max-height($value) {
	max-height: map.get(vm.$values, $value);
}

@mixin width($value) {
	width: map.get(vm.$values, $value);
}

@mixin min-width($value) {
	min-width: map.get(vm.$values, $value);
}

@mixin max-width($value) {
	max-width: map.get(vm.$values, $value);
}

@mixin square($value) {
	$v: map.get(vm.$values, $value);
	width: $v;
	height: $v;
}

@mixin min-square($value) {
	$v: map.get(vm.$values, $value);
	min-width: $v;
	min-height: $v;
}

@mixin max-square($value) {
	$v: map.get(vm.$values, $value);
	max-width: $v;
	max-height: $v;
}
