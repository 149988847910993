$primary-color: #73fbd3;
$secondary-color: #2C9A98;

$error-color: #d35151;

// Background colors
$primary-bg-light: #fff;
$secondary-bg-light: #F0F1F1;

$primary-bg-dark: #191919;
$secondary-bg-dark: #3b3b3b;

// Text colors
$primary-text-color-light: #fff;
$secondary-text-color-light: #F0F1F1;

$primary-text-color-dark: #191919;
$secondary-text-color-dark: #3b3b3b;
