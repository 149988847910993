@use '../../styles/layout/padding-margin'as pm;
@use '../../styles/layout/values-map'as vm;
@use '../../styles/layout/dimensions'as dim;
@use '../../styles/layout/mq'as mq;
@use '../../styles/variables'as v;
@use '../../styles/functions'as f;

.root {
	width: 100%;
	@include pm.margin-x(auto);

	@include mq.mq-width-from(mq.$break-width-s) {
		width: 85%;
	}

	@include mq.mq-width-from(mq.$break-width-xxl) {
		@include pm.padding-t(64);
		width: 100%;
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		padding-top: f.toFullHDVW(64);
	}
}

.title {
	text-align: center;
	@include pm.margin-b(28);

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		margin-bottom: f.toFullHDVW(28);
	}
}

.tagline {
	text-align: center;
	font-size: v.$font-rem-m;
	@include pm.margin-b(32);

	@include mq.mq-width-from(mq.$break-width-s) {
		@include pm.margin-b(64);
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		font-size: f.toFullHDVW(v.$font-size-m);
	}
}
