// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins

@mixin flexbox {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin inline-flex {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

@mixin flex($fg: 1, $fs: 0, $fb: auto) {

	// Set a variable to be used by box-flex properties
	$fg-boxflex: $fg;

	// Box-Flex only supports a flex-grow value so lets grab the
	// first item in the list and just return that.
	@if type-of($fg)=='list' {
		$fg-boxflex: nth($fg, 1);
	}

	-webkit-box-flex: $fg-boxflex;
	-moz-box: $fg-boxflex;
	-webkit-flex: $fg $fs $fb;
	-ms-flex: $fg $fs $fb;
	flex: $fg $fs $fb;
}

@mixin flex-direction($value: row) {
	@if $value==row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
		-moz-box-direction: reverse;
		-moz-box-orient: horizontal;
	}

	@else if $value==column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
		-moz-box-direction: normal;
		-moz-box-orient: vertical;
	}

	@else if $value==column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
		-moz-box-direction: reverse;
		-moz-box-orient: vertical;
	}

	@else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
		-moz-box-direction: normal;
		-moz-box-orient: horizontal;
	}

	-webkit-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
	// No Webkit/FF Box fallback.
	-webkit-flex-wrap: $value;

	@if $value==nowrap {
		-ms-flex-wrap: none;
	}

	@else {
		-ms-flex-wrap: $value;
	}

	flex-wrap: $value;
}

@mixin flex-flow($values: (row nowrap)) {
	// No Webkit/FF Box fallback.
	-webkit-flex-flow: $values;
	-ms-flex-flow: $values;
	flex-flow: $values;
}

@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int + 1;
	-moz-box-ordinal-group: $int + 1;
	-webkit-order: $int;
	-ms-flex-order: $int;
	order: $int;
}

.flex {
	@include flexbox;
}

.inline-flex {
	@include inline-flex;
}

.flex-v {
	@extend .flex;
	@include flex-direction(column);
}

.flex-center {
	@extend .flex;
	justify-content: center;
	align-items: center;
}

.flex-v-center {
	@extend .flex-v;
	justify-content: center;
}

.flex-h-center {
	@extend .flex;
	justify-content: center;
}

@mixin flexHorCenter() {
	@extend .flex-h-center;
}

@mixin flexVertCenter() {
	@extend .flex-v-center;
}
