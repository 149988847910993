@use './colours'as c;
@use './functions'as f;
@use './layout/mq'as mq;
@use './variables'as v;

// Defaults also get defined in _variables.scss
// and _normalize.scss in the normalize styles folder

p,
a,
li,
body,
html {
	font-size: v.$font-rem-default;

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		font-size: f.toFullHDVW(v.$font-size-default);
	}
}

h1 {
	font-size: v.$font-rem-l;

	@include mq.mq-width-from(mq.$break-width-s) {
		font-size: v.$font-rem-xl;
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		font-size: f.toFullHDVW(v.$font-size-xl);
	}
}

h2 {
	font-size: v.$font-rem-m;

	@include mq.mq-width-from(mq.$break-width-s) {
		font-size: v.$font-rem-l;
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		font-size: f.toFullHDVW(v.$font-size-l);
	}
}

h3 {
	font-size: v.$font-rem-default;

	@include mq.mq-width-from(mq.$break-width-s) {
		font-size: v.$font-rem-m;
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		font-size: f.toFullHDVW(v.$font-size-m);
	}
}

h4 {
	font-size: v.$font-rem-s;

	@include mq.mq-width-from(mq.$break-width-s) {
		font-size: v.$font-rem-default;
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		font-size: f.toFullHDVW(v.$font-size-default);
	}
}

h5 {
	font-size: v.$font-rem-xs;

	@include mq.mq-width-from(mq.$break-width-s) {
		font-size: v.$font-rem-s;
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		font-size: f.toFullHDVW(v.$font-size-s);
	}
}

h6 {
	font-size: v.$font-rem-xxs;

	@include mq.mq-width-from(mq.$break-width-s) {
		font-size: v.$font-rem-xs;
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		font-size: f.toFullHDVW(v.$font-size-xs);
	}
}

@for $i from 1 through 10 {
	$v: $i * 100;

	.fw#{$v} {
		font-weight: $v;
	}
}

.txt-prim {
	color: c.$primary-color;
}

.txt-sec {
	color: c.$secondary-color;
}

.txt-prim-light {
	color: c.$primary-text-color-light;
}

.txt-sec-light {
	color: c.$secondary-text-color-light;
}

.txt-prim-dark {
	color: c.$primary-text-color-dark;
}

.txt-sec-dark {
	color: c.$secondary-text-color-dark;
}
