@use '../../styles/layout/padding-margin'as pm;
@use '../../styles/layout/values-map'as vm;
@use '../../styles/layout/dimensions'as dim;
@use '../../styles/colours'as c;
@use '../../styles/layout/mq'as mq;
@use '../../styles/functions'as f;

.root {
	position: relative;
	background-color: c.$secondary-bg-light;
	min-height: 100vh;
	@include pm.padding-b(80);
}

.park-logo {
	display: flex;
	justify-content: center;
	@include pm.margin-y(24);

	@include mq.mq-width-from(mq.$break-width-s) {
		@include pm.margin-y(44);
	}

	@include mq.mq-width-from(mq.$break-width-xxl) {
		display: inline-block;
		justify-content: unset;
		position: absolute;
		left: vm.getValue(32);
		top: vm.getValue(32);
		@include pm.margin-y(0);
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		left: f.toFullHDVW(32);
		right: f.toFullHDVW(32);
	}

	&>figure {
		@include dim.square(96);

		@include mq.mq-width-from(mq.$break-width-s) {
			@include dim.square(128);
		}

		@include mq.mq-width-from(mq.$break-width-xxxl) {
			@include dim.square(128);
			width: f.toFullHDVW(128);
			height: f.toFullHDVW(128);
		}

		&>img {
			width: 100%;
			height: 100%;
		}
	}
}

.white-bg {
	position: absolute;
	z-index: 0;
	left: 0;
	right: 0;
	top: 0;
	height: 75vh;
	width: 100%;
	background-color: c.$primary-bg-light;

	@include mq.mq-width-from(mq.$break-width-xxxs) {
		height: 60vh;
	}

	@include mq.mq-width-from(mq.$break-width-s) {
		height: 50vh;
	}
}

.content {
	position: relative;
	z-index: 1;
}
