@use '../../styles/layout/padding-margin'as pm;
@use '../../styles/layout/values-map'as vm;
@use '../../styles/layout/dimensions'as dim;
@use '../../styles/variables'as v;
@use '../../styles/colours'as c;
@use '../../styles/layout/mq'as mq;
@use '../../styles/functions'as f;

.root {
	// height: 40vw;
	// background-color: rgba(#000000, 0.05);
}

$shadow: 0 10px 15px -3px rgba(0, 0, 0, .1),
0 4px 6px -2px rgba(0, 0, 0, 0.1);

.content-container {
	@include pm.margin-x(auto);

	background-color: c.$primary-bg-light;
	height: 100%;
	box-shadow: $shadow,
		$shadow; //$shadow;
	overflow: hidden;
	border-radius: v.$border-radius-default;

	@include mq.mq-width-from(mq.$break-width-s) {
		max-width: 85%;
	}

	@include mq.mq-width-from(mq.$break-width-xxl) {
		max-width: 53.7%;
	}
}

.video-container {
	overflow: hidden;
	position: relative;
	background-color: c.$primary-bg-dark;


	// Video auto resizes -> so not needed
	// @include mq.mq-width-from(mq.$break-width-s) {
	// 	@include dim.height(352);
	// }

	// @include mq.mq-width-from(mq.$break-width-l) {
	// 	@include dim.height(416);
	// }

	&>video {
		width: 100%;
		height: 100%;
		background-size: contain;
	}
}

.bottom-container {
	@include pm.padding-y(32);
	@include pm.padding-x(16);

	@include mq.mq-width-from(mq.$break-width-s) {
		@include pm.padding-x(30);
		@include pm.padding-t(32);
		@include pm.padding-b(40);
	}

	@include mq.mq-width-from(mq.$break-width-xxl) {
		@include pm.padding(32);
	}

	@include mq.mq-width-from(mq.$break-width-xxxl) {
		padding: f.toFullHDVW(32);
	}
}

.title {
	text-align: center;
	@include pm.margin-b(32);

	@include mq.mq-width-from(mq.$break-width-s) {
		@include pm.margin-b(40);
	}
}

.share-container {
	justify-content: center;
	@include pm.margin-b(16);

	@include mq.mq-width-from(mq.$break-width-s) {
		@include pm.margin-b(40);
	}
}

.share-link {
	transform: scale(1);
	transition: transform .2s ease-in-out;
	display: flex;
	justify-content: center;
	width: 20%;

	@include mq.mq-width-from(mq.$break-width-s) {
		width: 11%;
	}

	@include mq.mq-mouse() {
		&:hover {
			transform: scale(1.1);

			&>svg {
				fill: c.$secondary-color;
				stroke: c.$secondary-color;
			}
		}
	}

	&>svg {
		@include dim.square(36);
		@include pm.margin-b(20);

		@include mq.mq-width-from(mq.$break-width-s) {
			@include dim.square(48);
			@include pm.margin-b(0);
		}

		@include mq.mq-width-from(mq.$break-width-xxxl) {
			width: f.toFullHDVW(48);
			height: f.toFullHDVW(48);
		}

		transition: fill .2s,
		stroke .2s ease-in-out;
	}

	&.mobile-only {

		// Target mobile devices only
		@include mq.mq-mouse() {
			display: none;
		}
	}
}

// Discover the park container
.discover-container {
	text-align: center;
	@include pm.margin-x(auto);

	@include mq.mq-width-from(mq.$break-width-s) {
		width: 80%;
	}

	@include mq.mq-width-from(mq.$break-width-l) {
		width: auto;
		@include pm.margin-x(0);
	}

	&>p {
		@include pm.margin-b(16);

		@include mq.mq-width-from(mq.$break-width-xxxl) {
			margin-bottom: f.toFullHDVW(16);
		}
	}

	&>a {
		@include pm.margin-b(56);
		display: block;

		@include mq.mq-width-from(mq.$break-width-xxxl) {
			margin-bottom: f.toFullHDVW(56);
		}
	}
}

.rating-container {
	&>p {
		text-align: center;

		@include pm.margin-b(16);

		@include mq.mq-width-from(mq.$break-width-s) {
			font-size: v.$font-rem-s;
		}

		@include mq.mq-width-from(mq.$break-width-xxxl) {
			margin-bottom: f.toFullHDVW(16);
			font-size: f.toFullHDVW(v.$font-size-s);
		}
	}

	.jotform {
		height: f.rem(385);
		min-width: 100%;
		border: none;

		@include mq.mq-width-from(mq.$break-width-s) {
			height: f.rem(480);
		}

		@include mq.mq-width-from(mq.$break-width-xxxl) {
			height: f.toFullHDVW(220);
		}
	}
}
