@use '../../../styles/colours'as c;

.primary {
	fill: c.$primary-color;
	stroke: c.$primary-color;
}

.secondary {
	fill: c.$secondary-color;
	stroke: c.$secondary-color;
}

.primary-dark {
	fill: c.$primary-bg-dark;
	stroke: c.$primary-bg-dark;
}

.secondary-dark {
	fill: c.$secondary-bg-dark;
	stroke: c.$secondary-bg-dark;
}

.primary-light {
	fill: c.$primary-bg-light;
	stroke: c.$primary-bg-light;
}

.secondary-light {
	fill: c.$secondary-bg-light;
	stroke: c.$secondary-bg-light;
}
